@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.background {
  background-image: url("../public/poza_fundal.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow-y: auto;
  background-attachment: fixed;
}


.divConversatie {
  padding-left: 20%;
  padding-right: 20%;
}

.divConversatie::-webkit-scrollbar {
  display: none; 
}

.parteaDeJos{
  padding-left: 20%;
  padding-right: 20%;
}

.marginDreaptaCovAi{
  align-self: flex-end; 
  padding: 10px;

}
.marginStangaCovAi{
  align-self: flex-start; 
  padding: 10px;

}
.divSus{
  margin-bottom: 20px;
}

/*  */

.fullPageChat{
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.navbarChat{
  height: 10%;
}

.divDouaChaturi{
  flex-grow: 1; 
  display: flex;
  
  width: 100%;
  height: 100px; 
}
.divStanga, .divDreapta {
  height: 100%; 
  padding: 10px;
}
.divStanga {
  border-right: 2px solid grey;
}
.fullPage-second{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mes_part{
  height: 90%;
  overflow-y: scroll;

}


.input_part{
  flex-grow: 1;
}


.mes_part::-webkit-scrollbar {
  display: none; 
}

.cursor_pointer{
  cursor: pointer;
}

.max_length{
  max-height: 250px;
  overflow-y: scroll;
}
.max_length::-webkit-scrollbar {
  display: none; 
}

/**/

.setPage{
  display: flex;
  height: 100%;
  flex-direction: row;
}

.setPageNav{
  width: 20%;
  min-width: 120px;
  padding: 10px;
  border-right: 2px solid rgb(8, 8, 8);
  height: 100vh;
  padding-top: 20px;
}

.setPageCont{
  flex-grow: 1;
  padding: 10px;
  padding-top: 20px;

  /* height: 100vh;
  width: 100%; */
}

.butonInchisDeschis{
  position: absolute;
  margin-top: 5px;
  margin-left: 5px;
}

.alertPage{
  position: absolute;
  left: 50%; 
  transform: translateX(-50%);
  padding-top: 60px;
  z-index: 999;
}

.red{
  color: red;
}
.blue{
  color: blue;
}
.green{
  color: green;
}
.yellow{
  color: yellow;
} 

.inputAscuns{
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}


.scrollbar_hiden::-webkit-scrollbar {
  display: none;
}